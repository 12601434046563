import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { forEach, map, some, startsWith, reject } from 'lodash';
import styles from './AppSider.less';
import { isPermitted, Resource, Action } from '@helpers/permission';
import I18n from 'i18n-js';
const { Sider } = Layout;
// Use ES6 Map here to guarantee order
const shiftMenuItems = new Map([
    [
        '/shift-schedules/create',
        {
            label: 'Create Schedule',
            isHidden: () => !isPermitted(Resource.SHIFT, Action.CREATE),
        },
    ],
    [
        '/shift-schedules',
        {
            label: 'View Schedule',
            isHidden: () => !isPermitted(Resource.SHIFT, Action.READ),
        },
    ],
    [
        '/shifts/create',
        {
            label: 'Create Shift',
            isHidden: () => !isPermitted(Resource.SHIFT, Action.CREATE),
        },
    ],
    [
        '/shifts',
        {
            label: 'View Shift',
            isHidden: () => !isPermitted(Resource.SHIFT, Action.READ),
        },
    ],
    [
        '/shift-performance',
        {
            label: 'Shift Performance',
            isHidden: () => !isPermitted(Resource.SHIFT, Action.READ),
        },
    ],
]);
const streakMenuItems = new Map([
    [
        '/streaks',
        {
            label: 'View Streaks',
            isHidden: () => !isPermitted(Resource.VANGUARD_STREAK, Action.READ),
        },
    ],
]);
const autoZoneMenuItems = new Map([
    [
        '/auto-zones/create',
        {
            label: 'Create Auto Zone',
            isHidden: () => !isPermitted(Resource.AUTO_ZONING, Action.CREATE),
        },
    ],
    [
        '/auto-zones',
        {
            label: 'View Auto Zone',
            isHidden: () => !isPermitted(Resource.AUTO_ZONING, Action.READ),
        },
    ],
]);
const toolsMenuItems = new Map([
    [
        '/tool/explainability',
        {
            label: 'Explainability',
            isHidden: () => false,
        },
    ],
    [
        '/tool/scd-debugger',
        {
            label: 'SCD Debugger',
            isHidden: () => false,
        },
    ],
    [
        '/tool/geofence-uploader',
        {
            label: 'Geofence Uploader',
            isHidden: () => !isPermitted(Resource.STREAK, Action.CREATE),
        },
    ],
]);
const subMenuData = [
    {
        label: 'Shift',
        prefixes: ['/shifts', '/shift-schedules', '/shift-performance'],
        menu: shiftMenuItems,
    },
    {
        label: 'Streak',
        prefixes: ['/streaks'],
        menu: streakMenuItems,
    },
    {
        label: 'Auto Zone',
        prefixes: ['/auto-zones'],
        menu: autoZoneMenuItems,
    },
    {
        label: 'Tools',
        prefixes: ['/tool'],
        menu: toolsMenuItems,
    },
];
const getOpenKeys = (pathname) => {
    let keys = ['/'];
    forEach(subMenuData, ({ label, prefixes }) => {
        if (some(prefixes, (prefix) => startsWith(pathname, prefix))) {
            keys = [label];
        }
    });
    return keys;
};
const AppSider = () => {
    const location = useLocation();
    const renderSubMenu = (menuData) => map(menuData, ({ label: subMenuLabel, menu: items }) => (React.createElement(Menu.SubMenu, { key: subMenuLabel, title: subMenuLabel }, map(reject(Array.from(items), ([_, item]) => item.isHidden()), ([path, item]) => (React.createElement(Menu.Item, { key: path },
        React.createElement(Link, { to: path }, item.label)))))));
    return (React.createElement(Sider, { className: styles.sider },
        React.createElement(Link, { className: styles.logoWrapper, to: '/' },
            React.createElement("img", { alt: 'Supply Shaping Portal', className: styles.logo, src: require('public/images/logo.svg') }),
            React.createElement("span", { className: "logoText" }, I18n.t('common.supply_shaping_portal'))),
        React.createElement(Menu, { mode: "inline", defaultOpenKeys: getOpenKeys(location.pathname), selectedKeys: [location.pathname] },
            React.createElement(Menu.Item, { key: "/" },
                React.createElement(Link, { to: "/" }, "Home")),
            renderSubMenu(subMenuData))));
};
export default AppSider;
